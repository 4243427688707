<template>
	<div class="pages">
		<!--		<span :class="{ 'cur': curPage == 1 }" @click="first">&lt;&lt;</span>-->
		<!--		<span :class="{ 'cur': curPage == 1 }" @click="prev">上一页</span>-->
		<!--		<span v-if="curPageArrNum > 0" @click="prevPage">...</span>-->

		<span v-for="item in curPageArr" :class="{ 'cur': curPage == item }" @click="pageChange(item)">{{ item }}</span>

		<!--		<span v-if="curPageArrNum < Math.ceil(this.pages % this.pageArrNum) - 1" @click="nextPage">...</span>-->
		<!--		<span :class="{ 'cur': curPage == pages }" @click="next">下一页</span>-->
		<!--		<span :class="{ 'cur': curPage == pages }" @click="last">&gt;&gt;</span>-->
	</div>
</template>

<script>
	export default {
		name: "index",
		props: {
			total: {
				type: Number,
				default: 1
			},

			pageSize: {
				type: Number,
				default: 8
			},

			page: {
				type: Number,
				default: 1
			}
		},
		data: function() {
			return {
				pages: 1,
				curPage: this.page,

				pageArrNum: 5,
				curPageArrNum: 0
			}
		},
		watch: {
			page: function(val) {
				this.curPage = val;
			},

			total: function() {
				this.init();
			}
		},
		computed: {
			curPageArr: function() {
				var arr = [];
				for (var i = 1; i <= this.pageArrNum; i++) {
					var num = this.curPageArrNum * this.pageArrNum + i;
					if (num <= this.pages) {
						arr.push(num);
					}
				}
				return arr
			}
		},
		created: function() {
			this.init();
		},
		methods: {
			init: function() {
				this.pages = Math.ceil(this.total / this.pageSize);
				this.pageArrNum = this.pages < 5 ? this.pages : 5;
			},

			pageChange: function(item) {
				this.curPage = item;
				this.$emit("change", this.curPage);
			},

			first: function() {
				var curPage = this.curPage;
				if (curPage > 1) {
					this.pageChange(1);

					this.curPageArrNum = 0;
				}
			},

			last: function() {
				var curPage = this.curPage;
				if (curPage < this.pages) {
					this.pageChange(this.pages);

					this.curPageArrNum = Math.ceil(this.pages % this.pageArrNum) - 1;
				}
			},

			prev: function() {
				var curPage = this.curPage;
				if (curPage > 1) {
					var num = curPage - 1;
					this.pageChange(curPage - 1);

					var yu = num % this.pageArrNum;
					if (yu == 0 && num < this.pages) {
						this.curPageArrNum--;
					}
				}
			},

			next: function() {
				var curPage = this.curPage;
				if (curPage < this.pages) {
					var num = curPage + 1;
					this.pageChange(curPage + 1);

					var yu = num % this.pageArrNum;
					if (yu == 1 && num < this.pages) {
						this.curPageArrNum++;
					}
				}
			},

			prevPage: function() {
				this.curPageArrNum--;
			},

			nextPage: function() {
				this.curPageArrNum++;
			}
		}
	}
</script>

<style lang="less" scoped>
	.pages {
		width: 100%;
		margin: 15px 0;
		display: flex;
		justify-content: center;
	}

	.pages span {
		width: 30px;
		height: 30px;
		margin: 0 10px;
		font-size: 16px;
		border: 1px solid #BA983F;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #BA983F;
		cursor: pointer;
	}

	.pages .cur {
		color: #fff;
		background-color: #BA983F;
	}

	.pages .cur:hover {
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}

	@media screen and(max-width: 800px) {
		.pages {
			margin: 5px 0;
		}

		.pages span {
			width: 20px;
			height: 20px;
			margin: 0 5px;
			font-size: 14px;
		}
	}
</style>